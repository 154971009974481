let api_key_param = '';

export const fetchMyProfile = async api_key => {
  let rootURL = JSON.parse(sessionStorage.getItem('config')).api;
  api_key_param = api_key ? `?api_key=${api_key}` : '';
  let init;
  let objectName;

  return fetch(`${rootURL}/api/my_profile${api_key_param}`)
    .then(response => {
      if (response.ok) {
        return response.json().then(response => {
          // <MOCK
          // if(!api_key) {
          //   response = {...response, role: 'autologin', object: '6B31B20F-EB38-4C9B-9380-E62609E14F84'};
          // }
          // MOCK>

          init = response;

          if (init.role === 'autologin') {
            // <MOCK
            // return fetch(`${rootURL}/object?q=id:${response.object}&fields=object_group.name${api_key ? api_key_param : '&api_key=admin_key'}`)
            // MOCK>

            // ORIGINAL
            return fetch(
              `${rootURL}/object?q=id:${response.object}&fields=object_group.name${api_key_param}`,
            ).then(response => {
              if (response.ok) {
                return response.json().then(response => {
                  objectName = response.data[0]['object_group.name'];
                  init.objectName = objectName;
                  return init;
                });
              }
            });
          } else {
            return init;
          }
        });
      }
      if (response.status >= 100 && response.status < 200) {
        console.log(response.statusText);
      }
      if (response.status >= 300 && response.status < 399) {
        console.log(response.statusText);
      }
      if (response.status >= 400 && response.status < 499) {
        console.log(`${response.status} ${response.statusText} - check fetchMyProfile request`);
      }
      if (response.status >= 500 && response.status < 599) {
        console.log(response.statusText);
      }
    })
    .catch(error => {
      throw error;
    });
};

export const fetchObject = async (api_key, object) => {
  let rootURL = JSON.parse(sessionStorage.getItem('config')).api;
  api_key_param = api_key ? `&api_key=${api_key}` : '';

  return fetch(`${rootURL}/object?q=id:${object}&fields=id,status${api_key_param}`)
    .then(response => {
      if (response.ok) {
        return response.json().then(response => response);
      }
      if (response.status >= 100 && response.status < 200) {
        console.log(response.statusText);
      }
      if (response.status >= 300 && response.status < 399) {
        console.log(response.statusText);
      }
      if (response.status >= 400 && response.status < 499) {
        console.log(`${response.status} ${response.statusText} - check fetchObject request`);
        return response;
      }
      if (response.status >= 500 && response.status < 599) {
        console.log(response.statusText);
      }
    })
    .catch(error => console.log('Error fetchObject', error.message));
};

export const fetchOrder = async (api_key, orderData) => {
  let rootURL = JSON.parse(sessionStorage.getItem('config')).api;

  api_key_param = api_key ? `?api_key=${api_key}` : '';

  return fetch(`${rootURL}/order${api_key_param}`, {
    method: 'post',
    body: JSON.stringify(orderData),
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(response => response.data);
      }
      if (response.status >= 100 && response.status < 200) {
        console.log(response.statusText);
      }
      if (response.status >= 300 && response.status < 399) {
        console.log(response.statusText);
      }
      if (response.status >= 400 && response.status < 499) {
        console.log(`${response.status} ${response.statusText} - check fetchOrder request`);
        return response
          .json()
          .then(response => (response = { error: true, message: response.message }));
      }
      if (response.status >= 500 && response.status < 599) {
        console.log(response.statusText);
      }
    })
    .catch(error => console.log('Error fetchOrder', error.message));
};

export const fetchGetOrder = async api_key => {
  let rootURL = JSON.parse(sessionStorage.getItem('config')).api;

  api_key_param = api_key ? `?api_key=${api_key}` : '';

  return fetch(`${rootURL}/order${api_key_param}`)
    .then(response => {
      if (response.ok) {
        return response.json().then(response => response.data);
      }
      if (response.status >= 100 && response.status < 200) {
        console.log(response.statusText);
      }
      if (response.status >= 300 && response.status < 399) {
        console.log(response.statusText);
      }
      if (response.status >= 400 && response.status < 499) {
        console.log(`${response.status} ${response.statusText} - check fetchGetOrder request`);
        return response;
      }
      if (response.status >= 500 && response.status < 599) {
        console.log(response.statusText);
      }
    })
    .catch(error => console.log('Error fetchGetOrder', error.message));
};

export const fetchLoginToken = async data => {
  let rootURL = JSON.parse(sessionStorage.getItem('config')).api;

  let url = `${rootURL}/api/api_key`;
  let username = data.username;
  let password = data.password;

  return fetch(url, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(username + ':' + password),
    },
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(response => {
          sessionStorage.setItem('api_key', response.api_key);
          return response;
        });
      }
      if (response.status >= 100 && response.status < 200) {
        console.log(response.statusText);
      }
      if (response.status >= 300 && response.status < 399) {
        console.log(response.statusText);
      }
      if (response.status >= 400 && response.status < 499) {
        console.log(`${response.status} ${response.statusText} - check fetchLoginToken request`);
        return response;
      }
      if (response.status >= 500 && response.status < 599) {
        console.log(response.statusText);
        return response;
      }
    })
    .catch(error => {
      console.log('Error fetchLoginToken', error);
      return error;
    });
};

export const fetchObjectGroups = async () => {
  const config = JSON.parse(sessionStorage.getItem('config'));
  let rootURL = config.api;

  return await fetch(
    `${rootURL}/object_group?q=network_operator:${config.network_operator}&fields=id,name,visibility&sort=name&per_page=100`,
  )
    .then(response => {
      if (response.ok) {
        return response.json().then(response => response);
      }
    })
    .catch(error => console.log('Error fetchObjectGroups', error.message));
};
